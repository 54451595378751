// Libs
import React from 'react';
import loadableVisibility from 'react-loadable-visibility/loadable-components';
import Loadable from 'react-loadable';
// Hooks
import { useClassesStaticQuery } from '../../../hooks/useClassesStaticQuery';
// Deps
import PagePreloader from '../../common/loaders/page-preloader';
import CommandBar from '../classes-command-bar/command-bar';
// Assets
import styles from './class-listing.module.scss';

const ClassListing = ({ featuredClassId }) => {
  // State
  const [{ byClassNid }] = useClassesStaticQuery();
  // Local data
  const featuredClass = byClassNid.find((Class) => {return Class.class.nid === featuredClassId});

  // Render
  return (
    <div className={styles.classListing}>
      <CommandBar />
      <div className={styles.classListingContentBackground}>
        <div className={styles.classListingLoft} />
        {featuredClass && <ClassListingFeatured classSchedule={featuredClass} />}
        <AppCookingClassesListings />
      </div>
    </div>
  );
};

export default ClassListing;


const ClassListingFeatured = Loadable({
  loader: () => import('./class-listing-featured'),
  loading() {
    return <PagePreloader/>
  }
});

const AppCookingClassesListings = Loadable({
  loader: () => import('./class-cooking-list'),
  loading() {
    return <PagePreloader/>
  }
});

/*const ClassListingFeatured = loadableVisibility(() => import('./class-listing-featured'), {
  fallback: <PagePreloader/>,
});

const AppCookingClassesListings = loadableVisibility(() => import('./class-cooking-list'), {
  fallback: <PagePreloader/>,
});*/
