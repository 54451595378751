import React from 'react';
import { graphql } from 'gatsby';
import AppLayout from '../../components/layout/AppLayout';
import SEO from '../../components/layout/seo';
import ClassListing from '../../components/app/loft/class-listing';
import {formatFieldsValue} from "../../libs/formatFromGraph";
import { getViewingPlatform } from '../../libs/getViewingPlatform';

const CookingClassesPage = ({ data })  => {
  const pageData = formatFieldsValue(data)['configPagesCookingClasses'];
  const { iOS, tallDevice } = getViewingPlatform();
  return (
    <AppLayout>
      <SEO title="Cooking Classes" />
      {iOS && tallDevice && <div style={{ height: 40, backgroundColor: '#f8f4ed' }}></div>}
      <ClassListing featuredClassId={pageData.featuredClass.nid}/>
    </AppLayout>
  );
};

export default CookingClassesPage;

export const query = graphql`
  query {
    configPagesCookingClasses {
      relationships {
        featuredClass:field_featured_class {
          nid:drupal_internal__nid
        }
      }
    }
  }
`;
